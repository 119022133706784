<template>
  <div v-if="loading" class="loader">
    <b-spinner />
  </div>
  <b-row v-else>
    <b-col md="7">
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            {{ title }}
            <b-badge pill variant="light-primary">{{
              promocion.prCodigo
            }}</b-badge>
          </b-card-title>
          <b-form-group label="Activo" label-for="v-active">
            <b-form-checkbox
              id="v-active"
              inline
              switch
              v-model="promocion.prActivo"
            />
          </b-form-group>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col cols="12">
              <h5 class="mb-2">Datos Generales</h5>
            </b-col>
            <b-col md="6">
              <b-form-group label="Nombre" label-for="v-name">
                <b-form-input
                  id="v-name"
                  v-model="promocion.prNombre"
                  placeholder="Nombre"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Descripción" label-for="v-descripcion">
                <b-form-input
                  id="v-descripcion"
                  v-model="promocion.prDescripcion"
                  placeholder="Descripción"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="isPromocode" md="6">
              <b-form-group label="Código" label-for="v-codigo">
                <b-form-input
                  id="v-codigo"
                  v-model="promocion.prCodigo"
                  @input="
                    (v) => {
                      promocion.prCodigo = v.toUpperCase();
                    }
                  "
                  placeholder="Código"
                />
              </b-form-group>
            </b-col>

            <b-col v-if="isPromotarjeta" md="6">
              <b-form-group label="Banco" label-for="v-banco">
                <v-select
                  id="v-banco"
                  label="title"
                  v-model="promocion.prIdbanco"
                  :reduce="(b) => b.title"
                  :options="bancos"
                  :clearable="false"
                  @option:selected="onchangeBanco"
                />
              </b-form-group>
            </b-col>

            <b-col v-if="isPromotarjeta && promocion.prIdbanco" md="6">
              <b-form-group label="Tarjeta" label-for="v-tarjeta">
                <v-select
                  id="v-tarjeta"
                  label="title"
                  v-model="promocion.prIdtarjeta"
                  :reduce="(t) => t.title"
                  :options="tarjetas"
                  :clearable="false"
                  @option:selected="onchangeTarjeta"
                />
              </b-form-group>
            </b-col>

            <b-col v-if="isPromotarjeta && promocion.prIdtarjeta" md="6">
              <b-form-group label="Bin" label-for="v-bin">
                <!-- <v-select
                  label="biBin"
                  v-model="promocion.bines"
                  uid="key"
                  :options="mBines"
                  :clearable="false"
                  multiple
                /> -->
                <v-select
                  id="v-bin"
                  label="biBin"
                  v-model="promocion.bines"
                  uid="biIdbines"
                  :reduce="(obj) => obj.biIdbines"
                  :options="mBines"
                  :clearable="false"
                  multiple
                />

                <!-- <v-select
                  id="v-bin"
                  label="biBin"
                  v-model="promocion.bines"
                  value="biIdbines"
                  :options="mBines"
                  :clearable="false"
                  multiple
                >
                  <template #option="{ item }">
                    <span v-if="item.biBin">{{ item.biBin }}</span>
                    <span v-else>Missing biBin property</span>
                  </template>
                  <template #selected-option="{ item }">
                    {{ item.biBin }}
                  </template>
                  <template #option-label="{ item }">
                    {{ item.biBin }}
                  </template>
                  <template #option-selected="{ item }">
                    <span v-if="item.biBin">{{ item.biBin }}</span>
                  </template>
                  <template #option-value="{ item }">
                    {{ item.biIdbines }}
                  </template>
                </v-select> -->
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <h5>Fecha de validez</h5>
            </b-col>

            <b-col md="8">
              <b-form-group label="Fecha de Inicio" label-for="v-fecha-ini">
                <b-form-datepicker
                  id="fecha-ini"
                  :min="currentDate"
                  local="es"
                  v-model="promocion.prFechaInicio"
                  placeholder="Seleccione la fecha de inicio"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Hora de Inicio" label-for="v-hora-ini">
                <b-form-timepicker
                  id="v-hora-ini"
                  locale="en"
                  v-model="promocion.prHoraInicio"
                />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group label="Fecha de fin" label-for="v-fecha-fin">
                <b-form-datepicker
                  id="fecha-fin"
                  :min="promocion.prFechaInicio"
                  local="es"
                  v-model="promocion.prFechaFin"
                  placeholder="Seleccione la fecha de fin"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Hora de fin" label-for="v-hora-fin">
                <b-form-timepicker
                  id="v-hora-fin"
                  locale="en"
                  v-model="promocion.prHoraFin"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <h5>Cantidad de usos</h5>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Cantidad máxima de usos"
                label-for="v-cant-max"
              >
                <b-form-input
                  id="v-cant-max"
                  type="number"
                  step="1"
                  v-model="promocion.prCantidadMaximaPromocion"
                  placeholder="0 = ilimitado"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Cantidad máxima de uso por Cliente"
                label-for="v-cant-cliente"
              >
                <b-form-input
                  id="v-cant-cliente"
                  type="number"
                  step="1"
                  v-model="promocion.prCantidadUsoUsuario"
                  placeholder="0 = ilimitado"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="Monto Mínimo Compra" label-for="v-valor-min">
                <b-form-input
                  id="v-valor-min"
                  type="number"
                  step=".01"
                  v-model="promocion.prMontoMinimoCompra"
                  :value="maskedValue(promocion, 'prMontoMinimoCompra')"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Monto Máximo Compra" label-for="v-valor-max">
                <b-form-input
                  id="v-valor-max"
                  type="number"
                  step=".01"
                  v-model="promocion.prMontoMaximoCompra"
                  :value="maskedValue(promocion, 'prMontoMaximoCompra')"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col>
              <b-button
                class="mr-1"
                @click="savePromocode"
                variant="primary"
                :disabled="saving"
              >
                <b-spinner v-if="saving" />
                <div v-else>Guardar</div>
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col cols="12" md="5">
      <b-card>
        <b-row>
          <b-col cols="12">
            <h5>Tipo</h5>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <b-form-radio-group
                id="v-tipo"
                size="sm"
                buttons
                @change="onchangeTipo"
                v-model="promocion.prTipoPromocion"
                :options="tipos"
                button-variant="outline-primary"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              v-if="promocion.prTipoPromocion !== 6"
              :label="promocion.prTipoPromocion === 3 ? 'Porcentaje' : 'Monto'"
              label-for="v-valor"
            >
              <b-input-group
                :prepend="promocion.prTipoPromocion === 3 ? '%' : '$'"
              >
                <b-form-input
                  id="v-valor"
                  type="number"
                  v-model="promocion.prPorcentajeDescuento"
                  :placeholder="
                    promocion.prTipoPromocion === 3 ? 'Porcentaje' : 'Monto'
                  "
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <h5>Condiciones</h5>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Comercio" label-for="v-comercio">
              <b-form-radio-group
                id="v-comercio"
                size="sm"
                buttons
                @change="() => onChangeComercio(false)"
                v-model="promocion.comercios[0]"
                :options="comercios"
                button-variant="outline-primary"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="showStores">
            <b-row>
              <!-- people group 1 -->
              <b-col md="5">
                <h6>Tiendas disponibles</h6>

                <!-- Search -->
                <b-input-group class="input-group-merge mb-1">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" class="text-muted" />
                  </b-input-group-prepend>
                  <b-form-input
                    size="sm"
                    v-model="searchStore"
                    placeholder="Buscar..."
                    v-on:input="filteredStores"
                  />
                </b-input-group>

                <vue-perfect-scrollbar
                  :settings="perfectScrollbarSettings"
                  class="scrollable-container"
                >
                  <!-- draggable -->
                  <draggable
                    group="people"
                    :list="unAssigned"
                    class="mb-1 cursor-move drgcnt"
                  >
                    <div v-for="(store, idx) in unAssigned" :key="idx">
                      <b-badge class="mr-25 mb-25" variant="light-dark">
                        <small>{{ store.razonComercial }}</small>
                      </b-badge>
                    </div>
                  </draggable>
                </vue-perfect-scrollbar>
              </b-col>

              <b-col md="2" class="mt-1">
                <b-button variant="outline-primary" @click="selectAllStores">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
                  </svg>
                </b-button>
              </b-col>
              <!-- people group 2 -->
              <b-col md="5">
                <h6 class="text-primary">Tiendas seleccionadas</h6>

                <!-- Search -->
                <b-input-group class="input-group-merge mb-1">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" class="text-muted" />
                  </b-input-group-prepend>
                  <b-form-input
                    size="sm"
                    v-model="searchStoreSelected"
                    placeholder="Buscar..."
                    v-on:input="filteredStoresSelected"
                  />
                </b-input-group>

                <vue-perfect-scrollbar
                  :settings="perfectScrollbarSettings"
                  class="scrollable-container"
                >
                  <!-- draggable -->
                  <draggable
                    :list="assigned"
                    group="people"
                    class="mb-1 cursor-move drgcnt"
                    item-key="id"
                    @change="onChangeAssigned"
                  >
                    <div v-for="(store, idx) in assigned" :key="idx">
                      <b-badge class="mr-25 mb-25" variant="light-primary">
                        <small>{{ store.razonComercial }}</small>
                      </b-badge>
                    </div>
                  </draggable>
                </vue-perfect-scrollbar>
              </b-col>
            </b-row>
          </b-col>

          <!-- <b-col v-if="showStores" cols="12" md="6" class="my-1">
            <b-button
              size="sm"
              block
              style="font-size: 10px"
              @click="unselectAllStores"
              variant="outline-secondary"
            >
              Quitar todo
            </b-button>
          </b-col>

          <b-col v-if="showStores" cols="12" md="6" class="my-1">
            <b-button
              size="sm"
              block
              style="font-size: 10px"
              @click="selectAllStores"
              variant="outline-primary"
            >
              Seleccionar todo
            </b-button>
          </b-col> -->

          <b-col cols="12 mt-2">
            <b-form-group label="Valido para" label-for="v-validez">
              <b-form-radio-group
                id="v-validez"
                size="sm"
                buttons
                v-model="promocion.prClienteNuevo"
                :options="validos"
                button-variant="outline-primary"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="promocion.prTipoPromocion !== 6">
            <b-form-checkbox
              id="v-free-d"
              class="custom-control-primary"
              switch
              v-model="promocion.prEnvioGratis"
            >
              Free delivery
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BButton,
  BSpinner,
  BCardBody,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BInputGroup,
  BFormCheckbox,
  BFormDatepicker,
  BFormTimepicker,
  BFormRadioGroup,
  BInputGroupPrepend,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import { getAuthToken } from "@/auth/utils";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useRefHistory } from "@vueuse/core";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    vSelect,
    BSpinner,
    draggable,
    BCardBody,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormTimepicker,
    BFormRadioGroup,
    BInputGroupPrepend,
    BFormCheckboxGroup,
    VuePerfectScrollbar,
  },
  beforeMount() {
    const promises = [];
    let promocion
    promises.push(this.loadStores());
    promises.push(this.loadBodegas());
    if (this.$route.params.promo) {
      promocion = this.$route.params.promo;
      this.promocion = promocion
      if (this.isPromocode) this.loading = false;
      else promises.push(this.loadBines());
    } else {
      if (this.$route.path.includes("promocode"))
        this.promocionID = parseInt(
          this.$route.path.replace("/promocode/", "")
        );
      else
        this.promocionID = parseInt(
          this.$route.path.replace("/promotarjeta/", "")
        );
      //promises.push(this.getPromocion());
    }
    console.log('aqui: 0 0 ',  promocion)
    if (promocion) {
      this.promocion.prMontoMinimoCompra = parseFloat(
        promocion.prMontoMinimoCompra
      ).toFixed(2);
      this.promocion.prMontoMaximoCompra = parseFloat(
        promocion.prMontoMaximoCompra
      ).toFixed(2);
    }
   Promise.all(promises)
      .then((e) => {
        if (promocion) {
          this.promocion.bines = promocion.bines.filter((elemento, indice) => promocion.bines.indexOf(elemento) === indice);
          this.promocion.bines = this.bines.filter( b => this.promocion.bines.includes(b.biIdbines))
        }
      })
      .catch((e) => {
        this.error = e;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    const currentDate = new Date();
    return {
      bines: [],
      tipos: [
        { text: "Porcentaje", value: 3 },
        { text: "Monto", value: 4 },
        { text: "Free delivery", value: 6 },
      ],
      validos: [
        { text: "Todos los clientes", value: false },
        { text: "Clientes nuevos", value: true },
      ],
      comercios: [
        {
          text: "Market",
          value: 1,
        },
        {
          text: "Foodie",
          value: 2,
        },
        {
          text: "Plus",
          value: 5,
        },
      ],
      stores: [],
      saving: false,
      loading: true,
      allStores: [],
      promocion: {},
      storesPlus: [],
      searchStore: "",
      currentDate: currentDate,
      storesFoodie: [],
      localesPromocion: [],
      searchStoreSelected: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      flag: false,
      bodegasOptions: [],
      sectoresOptions: [],
      assigned: [],
      assignedCopy: [],
      unAssigned: [],
    };
  },
  methods: {
    onChangeAssigned(evt) {
      if (_.has(evt, "added")) {
        this.assignedCopy = [...this.assigned];
      }
      if (_.has(evt, "removed")) {
        const { element } = evt.removed;
        this.assignedCopy = [
          ..._.filter(
            this.assignedCopy,
            (ac) => ac.idEmpresaAsociado !== element.idEmpresaAsociado
          ),
        ];
      }
    },
    maskedValue(promocion, key) {
      var val;
      var masked;
      var returning_value;
      val = promocion[key];
      if (typeof val !== "undefined" && val !== "") {
        masked = this.maskValue(val);
        returning_value = parseFloat(masked).toFixed(2);
        promocion[key] = returning_value;
      }
      return masked;
    },
    maskValue(val) {
      if (typeof val !== "string") {
        val = val.toString();
      }
      if (isNaN(val)) return "0.00";
      val = val.replace(".", "");
      if (val.length == 1) {
        val = "0.0" + val;
      } else if (val.length == 2) {
        val = "0." + val;
      } else {
        val = +val.slice(0, -2) + "." + val.substr(-2);
      }
      return val;
    },
    onchangeTipo(tipo) {
      if (tipo === 6) this.promocion.prEnvioGratis = true;
      else this.promocion.prEnvioGratis = false;
    },
    onchangeBanco() {
      this.promocion.bines = null;
      this.promocion.PrIdtarjeta = null;
    },
    onchangeTarjeta() {
      this.promocion.bines = null;
    },
    loadBines() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.UrlMiddlewareGET, {
            path: "/bine/obtenertodos",
            body: JSON.stringify({tokenSesion: getAuthToken(),}),
          })
          .then((res) => {
            this.bines = res.data.map((o,i) => ({ ...o, key: i  }));
            const duplicates = this.bines.filter((v, i, a) => a.findIndex(t => (t.biIdbines === v.biIdbines)) !== i)
            resolve();
          })
          .catch((e) => {
            reject(e.message);
          });
      });
    },
    loadStores() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/MiTiendaObtenerLocales",
            body: JSON.stringify({
              marca: "false",
              IDEmpresa: 2,
              tokenSesion: getAuthToken(),
              TipoLineaComercial: "ALL",
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.allStores = res.data.lisEmpresaAsociada.map((s) => {
                s.esId = s.idEmpresaAsociado;
                return s;
              });
              this.storesPlus = this.allStores.filter(
                (s) => s.lineaDeNegocio === "OTROS_COMERCIOS"
              );
              this.storesFoodie = this.allStores.filter(
                (s) => s.lineaDeNegocio === "EATS"
              );
              this.onChangeComercio(true);
              resolve();
            } else {
              reject(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          });
      });
    },
    loadBodegas() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SectoresBodegas",
            body: JSON.stringify({
              otp: "V",
              empresa: 2,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.bodegasOptions = res.data.lsbodegas
                ? res.data.lsbodegas
                : [];
              this.sectoresOptions = res.data.lssectores
                ? res.data.lssectores
                : [];
              resolve();
            } else {
              reject(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          });
      });
    },
    onChangeComercio(fillPromo) {
      let hasPlus, hasFoodie;
      let storesAUX = [];
      const comercio = this.promocion.comercios[0]
      if (comercio === 2) {
        hasFoodie = true;
        storesAUX = [...this.storesFoodie];
        this.unAssigned = [...this.storesFoodie];
      } else if (comercio === 5) {
        hasPlus = true;
        storesAUX = [...storesAUX, ...this.storesPlus];
        this.unAssigned = [...storesAUX, ...this.storesFoodie];
      }
      if (fillPromo) {
        const localPromocions = JSON.parse(
          JSON.stringify(this.promocion.locales)
        ).filter((l) => this.allStores.some((s) => s.idEmpresaAsociado === l));
        this.assigned = localPromocions
          .map((l) => this.allStores.find((s) => s.idEmpresaAsociado === l))
          .sort((a, b) => {
            if (a.razonComercial > b.razonComercial) return 1;
            if (a.razonComercial < b.razonComercial) return -1;
            return 0;
          });
      } else {
        this.assigned = this.localesPromocion
          .filter((s) => {
            if (hasPlus && s.lineaDeNegocio === "OTROS_COMERCIOS") return true;
            if (hasFoodie && s.lineaDeNegocio === "EATS") return true;
            return false;
          })
          .sort((a, b) => {
            if (a.razonComercial > b.razonComercial) return 1;
            if (a.razonComercial < b.razonComercial) return -1;
            return 0;
          });
      }
      this.stores = storesAUX
        .filter(
          (s) =>
            !this.localesPromocion.some(
              (l) => l.idEmpresaAsociado === s.idEmpresaAsociado
            )
        )
        .sort((a, b) => {
          if (a.razonComercial > b.razonComercial) return 1;
          if (a.razonComercial < b.razonComercial) return -1;
          return 0;
        });
    },
    selectAllStores(event) {
      event.preventDefault();
      const aux = [...this.unAssigned]
      this.flag = !this.flag
      if ( this.flag ) {
        this.assigned = aux
        this.unAssigned = []
        return
      }
      this.unAssigned = [...this.assigned]
      this.assigned = []       
      // // this.onChangeComercio(false);
      // this.localesPromocion = this.stores;
      //this.stores = [];
      
    },
    unselectAllStores(event) {
      event.preventDefault();
      this.localesPromocion = [];
      this.onChangeComercio(false);
    },
    forceDecimal(num) {
      const value = `${num}`;
      value.replace(".", ",");
      return parseFloat(value).toFixed(2);
    },
    savePromocode(event) {
      event.preventDefault();
      this.saving = true;
      const promocion = JSON.parse(JSON.stringify(this.promocion));
      promocion.prMontoMinimoCompra = this.forceDecimal(
        promocion.prMontoMinimoCompra
      );
      promocion.prMontoMaximoCompra = this.forceDecimal(
        promocion.prMontoMaximoCompra
      );
      promocion.prPorcentajeDescuento = this.forceDecimal(
        promocion.prPorcentajeDescuento
      );
      promocion.prFechaFin = moment(promocion.prFechaFin).format(
        "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ"
      );
      promocion.prFechaInicio = moment(promocion.prFechaInicio).format(
        "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ"
      );
      // promocion.locales = this.localesPromocion.map((a) => a.idEmpresaAsociado);
      promocion.Locales = this.assigned.map((a) => a.idEmpresaAsociado);
      promocion["bodegas"] =
        this.bodegasOptions.length > 0 && this.promocion.comercios.includes(1)
          ? this.bodegasOptions.map((b) => b.id)
          : [];
      promocion["sectores"] = this.sectoresOptions.map((s) => s.id - 28);
      promocion.bines = promocion.bines.map( b => b.biIdbines)
      
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/CrearActualizarPromocion",
          body: JSON.stringify({
            empresa: 2,
            tokenSesion: getAuthToken(),
            promocion: promocion,
          }),
        })
        .then((res) => {
          this.saving = false;
          if (res.data.bOk) {
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se actualizo correctamente la promocion`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
          } else {
            this.$toast(
              {
                props: {
                  icon: "AlertTriangleIcon",
                  text: `Ocurrio un error al actualizar la promocion (${res.data.mensaje}).`,
                  title: "Error",
                  variant: "danger",
                },
                component: ToastificationContent,
              },
              {
                timeout: 10000,
              }
            );
          }
        })
        .catch((e) => {
          this.saving = false;
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al actualizar la promocion (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        });
    },
    filteredStores() {
      // Filtro para la busqueda
      this.unAssigned = [
        ..._.filter(this.stores, (ua) => {
          const { razonComercial } = ua;
          return _.lowerCase(razonComercial).includes(
            _.lowerCase(this.searchStore)
          );
        }),
      ];

      // Filtro para remover asigandos
      this.unAssigned = [
        ..._.filter(this.unAssigned, (ua) => {
          const { idEmpresaAsociado } = ua;
          return !_.some(this.assigned, { idEmpresaAsociado });
        }),
      ];
    },
    filteredStoresSelected() {
      // Filtro para la busqueda
      this.assigned = [
        ..._.filter(this.assignedCopy, (ua) => {
          const { razonComercial } = ua;
          return _.lowerCase(razonComercial).includes(
            _.lowerCase(this.searchStoreSelected)
          );
        }),
      ];
    },
  },
  computed: {
    title() {
      if (this.promocion.prTipo === "PROCO") return `Editar PromoCode`;
      else return `Editar PromoTarjeta`;
    },
    isPromocode() {
      return this.promocion.prTipo === "PROCO";
    },
    isPromotarjeta() {
      return this.promocion.prTipo === "PROTA";
    },
    showStores() {
      if (
        this.promocion &&
        this.promocion.comercios
        //this.promocion.comercios.length > 0
      ){
        const comercio = this.promocion.comercios[0]
        return comercio === 2 || comercio === 5;
      }
      return false;
    },
    bancos() {
      const bancos = [];
      this.bines.forEach((b,i) => {
        if (!bancos.some((bb) => bb.title === b.biBanco))
          bancos.push({ title: b.biBanco, id: i });
      });
      return bancos;
    },
    tarjetas() {
      const tarjetas = [];
      if (this.promocion.prIdbanco)
        this.bines
          .filter((b) => b.biBanco === this.promocion.prIdbanco)
          .forEach((b,i) => {
            if (!tarjetas.some((bb) => bb.title === b.biDescripcion))
              tarjetas.push({ title: b.biDescripcion , id: i});
          });
      return tarjetas;
    },
    mBines() {
      let bines = [];
      if (this.promocion.prIdtarjeta)
        bines = this.bines.filter(
          (b,i) => b.biDescripcion === this.promocion.prIdtarjeta && {...b, id: i}
        );
      const duplicates = bines.filter((v, i, a) => a.findIndex(t => (t.biIdbines === v.biIdbines)) !== i)
      return bines;
    },
  },
};
</script>
<style lang="scss">
.loader {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
