var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('div', {
    staticClass: "loader"
  }, [_c('b-spinner')], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s(_vm.promocion.prCodigo))])], 1), _c('b-form-group', {
    attrs: {
      "label": "Activo",
      "label-for": "v-active"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "v-active",
      "inline": "",
      "switch": ""
    },
    model: {
      value: _vm.promocion.prActivo,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prActivo", $$v);
      },
      expression: "promocion.prActivo"
    }
  })], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-2"
  }, [_vm._v("Datos Generales")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "v-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-name",
      "placeholder": "Nombre"
    },
    model: {
      value: _vm.promocion.prNombre,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prNombre", $$v);
      },
      expression: "promocion.prNombre"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Descripción",
      "label-for": "v-descripcion"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-descripcion",
      "placeholder": "Descripción"
    },
    model: {
      value: _vm.promocion.prDescripcion,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prDescripcion", $$v);
      },
      expression: "promocion.prDescripcion"
    }
  })], 1)], 1), _vm.isPromocode ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Código",
      "label-for": "v-codigo"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-codigo",
      "placeholder": "Código"
    },
    on: {
      "input": function input(v) {
        _vm.promocion.prCodigo = v.toUpperCase();
      }
    },
    model: {
      value: _vm.promocion.prCodigo,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prCodigo", $$v);
      },
      expression: "promocion.prCodigo"
    }
  })], 1)], 1) : _vm._e(), _vm.isPromotarjeta ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Banco",
      "label-for": "v-banco"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-banco",
      "label": "title",
      "reduce": function reduce(b) {
        return b.title;
      },
      "options": _vm.bancos,
      "clearable": false
    },
    on: {
      "option:selected": _vm.onchangeBanco
    },
    model: {
      value: _vm.promocion.prIdbanco,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prIdbanco", $$v);
      },
      expression: "promocion.prIdbanco"
    }
  })], 1)], 1) : _vm._e(), _vm.isPromotarjeta && _vm.promocion.prIdbanco ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tarjeta",
      "label-for": "v-tarjeta"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-tarjeta",
      "label": "title",
      "reduce": function reduce(t) {
        return t.title;
      },
      "options": _vm.tarjetas,
      "clearable": false
    },
    on: {
      "option:selected": _vm.onchangeTarjeta
    },
    model: {
      value: _vm.promocion.prIdtarjeta,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prIdtarjeta", $$v);
      },
      expression: "promocion.prIdtarjeta"
    }
  })], 1)], 1) : _vm._e(), _vm.isPromotarjeta && _vm.promocion.prIdtarjeta ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bin",
      "label-for": "v-bin"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-bin",
      "label": "biBin",
      "uid": "biIdbines",
      "reduce": function reduce(obj) {
        return obj.biIdbines;
      },
      "options": _vm.mBines,
      "clearable": false,
      "multiple": ""
    },
    model: {
      value: _vm.promocion.bines,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "bines", $$v);
      },
      expression: "promocion.bines"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v("Fecha de validez")])]), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha de Inicio",
      "label-for": "v-fecha-ini"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "fecha-ini",
      "min": _vm.currentDate,
      "local": "es",
      "placeholder": "Seleccione la fecha de inicio"
    },
    model: {
      value: _vm.promocion.prFechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prFechaInicio", $$v);
      },
      expression: "promocion.prFechaInicio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora de Inicio",
      "label-for": "v-hora-ini"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "id": "v-hora-ini",
      "locale": "en"
    },
    model: {
      value: _vm.promocion.prHoraInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prHoraInicio", $$v);
      },
      expression: "promocion.prHoraInicio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha de fin",
      "label-for": "v-fecha-fin"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "fecha-fin",
      "min": _vm.promocion.prFechaInicio,
      "local": "es",
      "placeholder": "Seleccione la fecha de fin"
    },
    model: {
      value: _vm.promocion.prFechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prFechaFin", $$v);
      },
      expression: "promocion.prFechaFin"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora de fin",
      "label-for": "v-hora-fin"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "id": "v-hora-fin",
      "locale": "en"
    },
    model: {
      value: _vm.promocion.prHoraFin,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prHoraFin", $$v);
      },
      expression: "promocion.prHoraFin"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v("Cantidad de usos")])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cantidad máxima de usos",
      "label-for": "v-cant-max"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-cant-max",
      "type": "number",
      "step": "1",
      "placeholder": "0 = ilimitado"
    },
    model: {
      value: _vm.promocion.prCantidadMaximaPromocion,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prCantidadMaximaPromocion", $$v);
      },
      expression: "promocion.prCantidadMaximaPromocion"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cantidad máxima de uso por Cliente",
      "label-for": "v-cant-cliente"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-cant-cliente",
      "type": "number",
      "step": "1",
      "placeholder": "0 = ilimitado"
    },
    model: {
      value: _vm.promocion.prCantidadUsoUsuario,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prCantidadUsoUsuario", $$v);
      },
      expression: "promocion.prCantidadUsoUsuario"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Monto Mínimo Compra",
      "label-for": "v-valor-min"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-valor-min",
      "type": "number",
      "step": ".01",
      "value": _vm.maskedValue(_vm.promocion, 'prMontoMinimoCompra'),
      "placeholder": ""
    },
    model: {
      value: _vm.promocion.prMontoMinimoCompra,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prMontoMinimoCompra", $$v);
      },
      expression: "promocion.prMontoMinimoCompra"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Monto Máximo Compra",
      "label-for": "v-valor-max"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-valor-max",
      "type": "number",
      "step": ".01",
      "value": _vm.maskedValue(_vm.promocion, 'prMontoMaximoCompra'),
      "placeholder": ""
    },
    model: {
      value: _vm.promocion.prMontoMaximoCompra,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prMontoMaximoCompra", $$v);
      },
      expression: "promocion.prMontoMaximoCompra"
    }
  })], 1)], 1), _c('b-col', [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary",
      "disabled": _vm.saving
    },
    on: {
      "click": _vm.savePromocode
    }
  }, [_vm.saving ? _c('b-spinner') : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v("Tipo")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-radio-group', {
    attrs: {
      "id": "v-tipo",
      "size": "sm",
      "buttons": "",
      "options": _vm.tipos,
      "button-variant": "outline-primary"
    },
    on: {
      "change": _vm.onchangeTipo
    },
    model: {
      value: _vm.promocion.prTipoPromocion,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prTipoPromocion", $$v);
      },
      expression: "promocion.prTipoPromocion"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.promocion.prTipoPromocion !== 6 ? _c('b-form-group', {
    attrs: {
      "label": _vm.promocion.prTipoPromocion === 3 ? 'Porcentaje' : 'Monto',
      "label-for": "v-valor"
    }
  }, [_c('b-input-group', {
    attrs: {
      "prepend": _vm.promocion.prTipoPromocion === 3 ? '%' : '$'
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-valor",
      "type": "number",
      "placeholder": _vm.promocion.prTipoPromocion === 3 ? 'Porcentaje' : 'Monto'
    },
    model: {
      value: _vm.promocion.prPorcentajeDescuento,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prPorcentajeDescuento", $$v);
      },
      expression: "promocion.prPorcentajeDescuento"
    }
  })], 1)], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v("Condiciones")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Comercio",
      "label-for": "v-comercio"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "v-comercio",
      "size": "sm",
      "buttons": "",
      "options": _vm.comercios,
      "button-variant": "outline-primary"
    },
    on: {
      "change": function change() {
        return _vm.onChangeComercio(false);
      }
    },
    model: {
      value: _vm.promocion.comercios[0],
      callback: function callback($$v) {
        _vm.$set(_vm.promocion.comercios, 0, $$v);
      },
      expression: "promocion.comercios[0]"
    }
  })], 1)], 1), _vm.showStores ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('h6', [_vm._v("Tiendas disponibles")]), _c('b-input-group', {
    staticClass: "input-group-merge mb-1"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "text-muted",
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "size": "sm",
      "placeholder": "Buscar..."
    },
    on: {
      "input": _vm.filteredStores
    },
    model: {
      value: _vm.searchStore,
      callback: function callback($$v) {
        _vm.searchStore = $$v;
      },
      expression: "searchStore"
    }
  })], 1), _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('draggable', {
    staticClass: "mb-1 cursor-move drgcnt",
    attrs: {
      "group": "people",
      "list": _vm.unAssigned
    }
  }, _vm._l(_vm.unAssigned, function (store, idx) {
    return _c('div', {
      key: idx
    }, [_c('b-badge', {
      staticClass: "mr-25 mb-25",
      attrs: {
        "variant": "light-dark"
      }
    }, [_c('small', [_vm._v(_vm._s(store.razonComercial))])])], 1);
  }), 0)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "2"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.selectAllStores
    }
  }, [_c('svg', {
    staticClass: "bi bi-arrow-left-right",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "fill": "currentColor",
      "viewBox": "0 0 16 16"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
    }
  })])])], 1), _c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('h6', {
    staticClass: "text-primary"
  }, [_vm._v("Tiendas seleccionadas")]), _c('b-input-group', {
    staticClass: "input-group-merge mb-1"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "text-muted",
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "size": "sm",
      "placeholder": "Buscar..."
    },
    on: {
      "input": _vm.filteredStoresSelected
    },
    model: {
      value: _vm.searchStoreSelected,
      callback: function callback($$v) {
        _vm.searchStoreSelected = $$v;
      },
      expression: "searchStoreSelected"
    }
  })], 1), _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('draggable', {
    staticClass: "mb-1 cursor-move drgcnt",
    attrs: {
      "list": _vm.assigned,
      "group": "people",
      "item-key": "id"
    },
    on: {
      "change": _vm.onChangeAssigned
    }
  }, _vm._l(_vm.assigned, function (store, idx) {
    return _c('div', {
      key: idx
    }, [_c('b-badge', {
      staticClass: "mr-25 mb-25",
      attrs: {
        "variant": "light-primary"
      }
    }, [_c('small', [_vm._v(_vm._s(store.razonComercial))])])], 1);
  }), 0)], 1)], 1)], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12 mt-2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Valido para",
      "label-for": "v-validez"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "v-validez",
      "size": "sm",
      "buttons": "",
      "options": _vm.validos,
      "button-variant": "outline-primary"
    },
    model: {
      value: _vm.promocion.prClienteNuevo,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prClienteNuevo", $$v);
      },
      expression: "promocion.prClienteNuevo"
    }
  })], 1)], 1), _vm.promocion.prTipoPromocion !== 6 ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "v-free-d",
      "switch": ""
    },
    model: {
      value: _vm.promocion.prEnvioGratis,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prEnvioGratis", $$v);
      },
      expression: "promocion.prEnvioGratis"
    }
  }, [_vm._v(" Free delivery ")])], 1) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }